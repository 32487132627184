import {splitCSVString, splitFullNameString, splitIntegerCSVString} from "../../utils/splitIntegerCSVString";
import { consumerDataSearchFields } from '../../constants/agentSearchFields';

export class SearchFormState {
    quicksearch_exclude = false
    quicksearch = '';

    npn_select = [
        {
            exclude: false,
            npn_number: [],
        }
    ];
    npns = [];
    npn_search_states = false;
    first_name = [];
    last_name = [];
    search_business_address = true;
    search_mailing_address = true;
    search_home_address = true;
    street_address = '';
    city = '';
    zipcodes = [
        {
            zip: "",
            radius: "",
        }
    ]
    area_code = '';
    phone_number = '';
    county = [];

    address_states = [
        {
            exclude: false,
            address_states_bool: 'or',
            states: [],
        }
    ];
    emails = [];
    email_address = [
        {
            exclude: false,
            email_address_bool: 'or',
            emails: [],
        }
    ];
    full_names = '';
    full_name = [
        {
            exclude: false,
            full_names: [],
        }
    ];

    gender = '';
    dob = false;
    dob_range = [1900, 2024];
    filter_income = false;
    income_range = [0, 500000];
    filter_house_value = false;
    house_value_range = [0, 1000000];
    email_level = '';
    filter_primary_phone = false;
    filter_is_agent = 'agents';

    license_states_and_groups = [
        {
            'exclude': false,
            'license_states_bool': 'or',
            'license_states': [],
            'license_groups_bool': 'or',
            'license_groups': {
                "": {
                    i: 0,
                    range: [1, 50]
                },
            }
        }
    ]
    years_licensed_range = [0, 50];

    carriers_and_groups = [
        {
            'carrier_groups': [],
            'carrier_groups_exclude': false,
            'carriers': [],
            'carriers_exclude': false,
        }
    ]
    carrier_groups_bool = false;
    carrier_count_range = null;
    carrier_group_range = null;

    filter_captive = 'both';
    captive_companies = [];

    report_type = 'default';
    search_type = '';
    export_id = '';
    export_name = '';
    additional_fields_exclude = {};

    constructor(data) {
        // initial consumer data field values
        consumerDataSearchFields.forEach(field => this[field.searchFieldName] = field.initialValue);
        if (data) {
            this.hydrate(data);
        }
    }

    forServer() {
        // if we need to reformat any data from the client representation (this class) for the server,
        // do it here
        let data = JSON.parse(JSON.stringify(this));
        data['npns'] = splitIntegerCSVString(data['npns']);
        data['email_address'] = splitCSVString(data['email_address']);
        data['full_name'] = splitFullNameString(data['full_name']);
        data['first_name'] = splitFullNameString(data['first_name']);
        data['last_name'] = splitFullNameString(data['last_name']);
        return data;
    }

    hydrate(data) {
        const updatedData = this.updateSavedSearch(data);
        for (const property in updatedData) {
            if (this.hasOwnProperty(property)) {
                this[property] = updatedData[property];
            }
        }
    }

    updateSavedSearch(data) {
        // when we make any backwards incompatible changes to the search params, this is where we should handle
        // older "saved search" data from the DB
        if ('address_state' in data && data['address_state']) {
            data['address_states'] = [data['address_state']];
        }

        if ('carrier_groups' in data) {
            data['carriers_and_groups'] = [
                {
                    'carrier_groups': data['carrier_groups'],
                    'carrier_groups_exclude': data['carrier_groups_exclude'],
                    'carriers': data['carriers'],
                    'carriers_exclude': data['carriers_exclude'],
                }
            ]
        }
        if ('license_groups' in data || 'license_states' in data) {
            data['license_states_and_groups'] = [
                {
                    'exclude': false,
                    'license_states_bool': data['license_states_bool'],
                    'license_states': data['license_states'],
                    'license_groups_bool': data['license_groups_bool'],
                    'license_groups': data['license_groups']
                }
            ]
        }
        if ('npn' in data && data['npn'].trim()) {
            data['npns'] = [data['npn'].trim()];
            data['npn_select'] = [data['npn'].trim()];
        }

        return data;
    }
};


export class RiaFirmSearchState {
    search_type = 'riaFirmSearch';
    quicksearch_exclude = false
    search_executive = false
    quicksearch = '';
    firm_names = [];
    crds = [];
    report_type = 'default';
    asset_types_and_amounts = {
        'default': { i: 1 }
    };
    street_address = '';
    city = '';
    area_code = '';
    phone_number = '';
    zipcodes = [
        {
            zip: "",
            radius: "",
        }
    ]
    address_states = [
        {
            exclude: false,
            address_states_bool: 'or',
            states: [],
        }
    ];
    county = [];
    registration_status = '';
    report_type = 'default';
    employee_and_rep_count = {
        'default': { i: 1 }
    };
    employee_and_rep_count_bool = 'or';
    additional_fields_exclude = {};
    compensation = [];
    advisory_services = [];
    other_business = [];
    affiliations = [];
    ria_types = [];
    terminated = [];
    discretion_over = [];
    referral_compensation = [];
    other_business_activities = [];
    otheractivities_brokerdealer = [];
    export_id = '';
    export_name = '';
    executive_titles = [
        {
            exclude: false,
            executive_titles_bool: 'or',
            titles: [],
        }
    ]
    constructor(data) {
        consumerDataSearchFields.forEach(field => this[field.searchFieldName] = field.initialValue);
        if (data) {
            this.hydrate(data);
        }
    }

    forServer() {
        // if we need to reformat any data from the client representation (this class) for the server,
        // do it here
        let data = JSON.parse(JSON.stringify(this));
        data['crds'] = splitIntegerCSVString(data['crds']);
        return data;
    }

    hydrate(data) {
        const updatedData = this.updateSavedSearch(data);
        for (const property in updatedData) {
            if (this.hasOwnProperty(property)) {
                this[property] = updatedData[property];
            }
        }
    }

    updateSavedSearch(data) {
        return data;
    }
};


export class IarSearchState {
    search_type = 'riaIndivSearch';
    quicksearch_exclude = false
    quicksearch = '';
    crds = [];
    first_name = [];
    last_name = [];
    firm_crds = [];
    firm_crds_list = [
        {
            exclude: false,
            crds: [],
        }
    ];
    firm_names = [
        {
            exclude: false,
            names: [],
        }
    ];

    street_address = '';
    city = '';
    zipcodes = [
        {
            zip: "",
            radius: "",
        }
    ]
    area_code = '';
    phone_number = '';
    county = [];
    high_net_worth_clients = '';
    other_business_activities = [];
    otheractivities_brokerdealer = [];
    disclosure_types = [
        {
            exclude: true,
            disclosure_type_bool: 'or',
            types: [],
        }
    ];
    designation_types = [
        {
            exclude: false,
            designation_type_bool: 'or',
            types: [],
        }
    ];
    otherbusiness_types = [
        {
            exclude: false,
            otherbusiness_types_bool: 'or',
            types: [],
        }
    ];
    ria_types = [
        {
            exclude: false,
            riatypes_type_bool: 'or',
            types: [],
        }
    ];
    linkedin_profile = '';
    dually_licensed= '';
    gender = ''
    address_states = [
        {
            exclude: false,
            address_states_bool: 'or',
            states: [],
        }
    ];
    license_types = [
        {
            exclude: false,
            license_type_bool: 'or',
            types: [],
        }
    ];
    emails = [];
    email_address = [
        {
            exclude: false,
            email_address_bool: 'or',
            emails: [],
        }
    ];
    full_names = '';
    full_name = [
        {
            exclude: false,
            full_names: [],
        }
    ];

    email_level = '';

    report_type = 'default';
    years_employeed = false;
    years_employeed_range = [1970, new Date().getFullYear()];
    states_registered = false;
    states_registered_range = [1, 50];
    export_id = '';
    export_name = '';

    constructor(data) {
        if (data) {
            this.hydrate(data);
        }
    }

    forServer() {
        // if we need to reformat any data from the client representation (this class) for the server,
        // do it here
        let data = JSON.parse(JSON.stringify(this));
        data['crds'] = splitIntegerCSVString(data['crds']);
        data['firm_crds'] = splitIntegerCSVString(data['firm_crds']);
        data['firm_crds_list'][0]['crds'] = splitIntegerCSVString(data['firm_crds']);
        data['first_name'] = splitFullNameString(data['first_name']);
        data['last_name'] = splitFullNameString(data['last_name']);
        data['full_name'] = splitFullNameString(data['full_name']);
        data['email_address'] = splitCSVString(data['email_address']);
        return data;
    }

    hydrate(data) {
        const updatedData = this.updateSavedSearch(data);
        for (const property in updatedData) {
            if (this.hasOwnProperty(property)) {
                this[property] = updatedData[property];
            }
        }
    }

    updateSavedSearch(data) {
        return data;
    }
};
export class BdSearchState {
    search_type = 'bdFirmSearch';
    search_executive = false
    quicksearch_exclude = false
    quicksearch = '';
    firm_names = [];
    crds = [];
    asset_types_and_amounts = {
        'total': { i: 1 }
    };
    street_address = '';
    city = '';
    area_code = '';
    phone_number = '';
    zipcodes = [
        {
            zip: "",
            radius: "",
        }
    ]
    address_states = [
        {
            exclude: false,
            address_states_bool: 'or',
            states: [],
        }
    ];
    county = [];
    report_type = 'default';
    other_business_activities = [];
    branch_count_range = [0, 'all'];
    contact_count_range = [0, 'all'];
    disclosure = '';
    search_with_addresses = true;
    search_mailing_address = true;
    search_office_address = true;
    export_id = '';
    export_name = '';
    disclosure_types = [
        {
            exclude: true,
            disclosure_type_bool: 'or',
            types: [],
        }
    ];

    executive_titles = [
        {
            exclude: false,
            executive_titles_bool: 'or',
            titles: [],
        }
    ]
    constructor(data) {
        consumerDataSearchFields.forEach(field => this[field.searchFieldName] = field.initialValue);
        if (data) {
            this.hydrate(data);
        }
    }

    forServer() {
        // if we need to reformat any data from the client representation (this class) for the server,
        // do it here
        let data = JSON.parse(JSON.stringify(this));
        data['crds'] = splitIntegerCSVString(data['crds']);
        return data;
    }

    hydrate(data) {
        const updatedData = this.updateSavedSearch(data);
        for (const property in updatedData) {
            if (this.hasOwnProperty(property)) {
                this[property] = updatedData[property];
            }
        }
    }

    updateSavedSearch(data) {
        return data;
    }
};
export class RrSearchState {
    search_type = 'rrIndivSearch';
    quicksearch_exclude = false
    quicksearch = '';
    crds = [];
    first_name = [];
    last_name = [];
    email_level = '';
    linkedin_profile='';
    dually_licensed= '';
    firm_crds = [];
    firm_crds_list = [
        {
            exclude: false,
            crds: [],
        }
    ];
    firm_names = [
        {
            exclude: false,
            names: [],
        }
    ];

    street_address = '';
    city = '';
    zipcodes = [
        {
            zip: "",
            radius: "",
        }
    ]
    area_code = '';
    phone_number = '';
    county = [];
    gender = ''
    disclosure = ''
    address_states = [
        {
            exclude: false,
            address_states_bool: 'or',
            states: [],
        }
    ];
    emails = [];
    email_address = [
        {
            exclude: false,
            email_address_bool: 'or',
            emails: [],
        }
    ];
    full_names = '';
    full_name = [
        {
            exclude: false,
            full_names: [],
        }
    ];

    other_business_activities = [];
    disclosure_types = [
        {
            exclude: true,
            disclosure_type_bool: 'or',
            types: [],
        }
    ];
    designation_types = [
        {
            exclude: false,
            designation_type_bool: 'or',
            types: [],
        }
    ];
    otherbusiness_types = [
        {
            exclude: false,
            otherbusiness_types_bool: 'or',
            types: [],
        }
    ];

    finra_registered = '';
    employment_count = false;
    employment_count_range = [1, 70];
    years_employeed = false;
    years_employeed_range = [1950, new Date().getFullYear()];
    years_employed_range_count = {
        'default': { i: 1 }
    };
    years_employed_count_bool = 'or';
    search_private_residence = true;
    search_branch_office_location = true;
    search_supervised_by_branch_office = true;
    license_types = [
        {
            exclude: false,
            license_type_bool: 'or',
            types: [],
        }
    ];

    report_type = 'default'
    export_id = '';
    export_name = '';

    constructor(data) {
        if (data) {
            this.hydrate(data);
        }
    }

    forServer() {
        // if we need to reformat any data from the client representation (this class) for the server,
        // do it here
        let data = JSON.parse(JSON.stringify(this));
        data['crds'] = splitIntegerCSVString(data['crds']);
        data['firm_crds'] = splitIntegerCSVString(data['firm_crds']);
        data['firm_crds_list'][0]['crds'] = splitIntegerCSVString(data['firm_crds']);
        data['first_name'] = splitFullNameString(data['first_name']);
        data['last_name'] = splitFullNameString(data['last_name']);
        data['full_name'] = splitFullNameString(data['full_name']);
        data['email_address'] = splitCSVString(data['email_address']);
        return data;
    }

    hydrate(data) {
        const updatedData = this.updateSavedSearch(data);
        for (const property in updatedData) {
            if (this.hasOwnProperty(property)) {
                this[property] = updatedData[property];
            }
        }
    }

    updateSavedSearch(data) {
        return data;
    }
};
